

























import TheSidebar from '@/containers/TheSidebar.vue';
import TheHeader from '@/containers/TheHeader.vue';
import TheFooter from '@/containers/TheFooter.vue';
import TheAside from "@/containers/TheAside.vue";

import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    TheSidebar,
    TheHeader,    
    TheFooter,
    TheAside
  },
})

export default class TheContainer extends Vue {
  private ui = vxm.ui;
  private get showHeader(): boolean {return this.$route.name !== 'SignInByEmail';}
}

