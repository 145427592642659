















































































































































import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
} from "vuelidate/lib/validators";

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      userName: {
        required,
        minLength: minLength(5),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex(
          "strongPass",
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        ),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      accept: {
        required,
        mustAccept: (val) => val,
      },
    },
  },
})
export default class TheAside extends Vue {
  private ui = vxm.ui;
  private form = this.getEmptyForm();
  private submitted = false;
  private page = 1;

  private toggleAside() {
    this.ui.toggleAside();
  }

  getEmptyForm() {
    return {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      accept: false,
    };
  }

  get formString() {
    return JSON.stringify(this.form, null, 4);
  }

  get isValid() {
    return !this.$v.form.$invalid;
  }

  get isDirty() {
    return this.$v.form.$anyDirty;
  }

  checkIfValid(fieldName: any) {
    const field = this.$v.form[fieldName];
    if (field == undefined) {
      return false;
    }
    return !field.$dirty ? null : !(field.$invalid || field.$model === "");
  }

  submit() {
    if (this.isValid) {
      this.submitted = true;
    }
  }

  validate() {
    this.$v.$touch();
  }

  reset() {
    this.form = this.getEmptyForm();
    this.submitted = false;
    this.$v.$reset();
  }
}
